<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <template v-slot:search-bar>
      <ion-toolbar>
        <ion-segment value="active" @ionChange="segmentChanged($event)">
          <ion-segment-button value="active">
            <ion-label>Active</ion-label>
          </ion-segment-button>
          <ion-segment-button value="upcoming">
            <ion-label>Upcoming</ion-label>
          </ion-segment-button>
          <ion-segment-button value="closed">
            <ion-label>Closed</ion-label>
          </ion-segment-button>
          <ion-segment-button value="all">
            <ion-label>All</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </template>
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-subject
            :resources="resources"
            :examUser="examUser"
            :title="title"
          ></list-subject>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import ListSubject from "../../Components/Exam/ExamSubject/ListSubject";

import {
  IonLabel,
  IonSegmentButton,
  IonSegment,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

export default {
  props: ["exam", "title"],

  components: {
    IonLabel,
    IonSegmentButton,
    IonSegment,
    IonToolbar,

    BaseLayout,
    ListSubject,
    IonGrid,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      backLink: "exam.list",
      reloadable: true,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["examSubject/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
    examUser() {
      return this.$store.getters["examUser/resources"];
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("examSubject/resources", {
        refresh: refresh,
        resourceId: this.exam,
      });
    },
    loadData(event) {
      this.fetch(false);
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("examSubject/resources", {
        refresh: true,
        resourceId: this.exam,
      });
      if (event) {
        event.target.complete();
      }
    },
    async segmentChanged(event) {
      await this.$store.dispatch("examSubject/segment", {
        segment: event.target.value,
        exam_id: this.exam,
      });
    },

    async fetchExamUser() {
      const user = await this.$store.getters["auth/user"];
      let data = {
        exam_id: this.exam,
        user_id: user.id,
      };
      await this.$store.dispatch("examUser/filters", data);
    },
  },

  created() {
    this.fetch();
    this.fetchExamUser();
  },
};
</script>

<style scoped>
</style>
