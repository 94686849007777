<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-card
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
      lines="none"
    >
      <ion-card-header>
        <span v-if="examUser.data.length > 0">
          <ion-card-subtitle
            v-if="checkExamUserState() != true"
            color="danger"
          >
            Warning: Unpaid Dues!
          </ion-card-subtitle>
        </span>
        <ion-card-title> {{ item.subject.name }} </ion-card-title>
        <ion-card-subtitle
          >Class: {{ item.subject.standard.name }}</ion-card-subtitle
        >
        <ion-card-subtitle class="dark-text">
          Date: {{ moment(item.exam_schedule.start).format("ll") }}
        </ion-card-subtitle>
        <ion-card-subtitle class="dark-text">
          Time:
          {{
            moment(item.exam_schedule.start).format("LT") +
            " to " +
            moment(item.exam_schedule.end).format("LT")
          }}
        </ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <ion-grid>
          <ion-row class="ion-align-items-center ion-no-padding dark-text">
            <ion-col class="ion-no-padding">
              <p>Full: {{ item.full_mark }}</p>
            </ion-col>
            <ion-col>
              <p>Pass: {{ item.pass_mark }}</p>
            </ion-col>
            <ion-col>
              <p>Negative: {{ item.negative_percentage }}%</p>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-button
          @click="startOnlineExam(item)"
          :disabled="item.exam_subject_state.name != 'Active'"
          expand="block"
          fill="solid"
          :color="btnColor(item)"
          >{{ btnText(item) }}</ion-button
        >
      </ion-card-content>
    </ion-card>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No subjects found!</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  alertController,
} from "@ionic/vue";

import moment from "moment";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    examUser: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      moment,
    };
  },

  computed: {
    examUserState() {
      if (!this.examUser) {
        return null;
      }
      return this.examUser.data[0].exam_user_state.name;
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },
    btnText(item) {
      if (item.exam_subject_state.name == "Created") {
        return "Not Started";
      }
      if (item.exam_subject_state.name == "Active") {
        return "Start Exam";
      }
      if (
        item.exam_subject_state.name == "Evaluating" ||
        item.exam_subject_state.name == "Locked"
      ) {
        return "Exam Closed";
      }
    },

    btnColor(item) {
      if (item.exam_subject_state.name == "Created") {
        return "primary";
      }
      if (item.exam_subject_state.name == "Active") {
        return "success";
      }
      if (
        item.exam_subject_state.name == "Evaluating" ||
        item.exam_subject_state.name == "Locked"
      ) {
        return "medium";
      }
    },

    async checkExamUserState() {
      return await this.examUser.data[0].exam_user_state.name == "Active" || this.examUser.data[0].exam_user_state.name == "Provisional";
    },

    async startOnlineExam(item) {
      if ((await this.checkExamUserState()) == false) {
        this.offerPayment();
      } else {
        let exam_name = this.title;
        let subject_name = item.subject.name;

        await this.$store.dispatch("examAnswer/createResource", {
          exam_subject_id: item.id,
        });

        this.$router.push({
          name: "exam.online",
          params: {
            exam_subject_id: item.id,
            exam_name,
            subject_name,
          },
        });
      }
    },

    async offerPayment() {
      const alertDialog = await this.alerter(
        "Unpaid Due!",
        "Please clear all unpaid dues or contact principal for permission to continue.",
        [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Pay Now",
            handler: () => {
              this.$router.push({
                name: "invoice",
              });
            },
          },
        ]
      );
      alertDialog.present();
    },
  },
};
</script>

<style scoped>
.dark-text {
  color: #000;
}
</style>